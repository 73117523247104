import React from "react";
import { AlertItem, AlertNews } from "../alert-types";
import { useLocation, useParams } from 'react-router-dom';
import useAlert from "../../../../hooks/useAlert";
import { BASE_URL } from "../../../../utility/constants";
import styled from "styled-components";
import colors from '../../../../styles/colors.js';
import moment from 'moment';
import CopyToClipboardButton from "../../../../components/CopyToClipboard";
import useAlertJobs from "../../../../hooks/useAlertJobs";

interface RouteParams {
    id: string
}

const StyledContainer = styled.div`
  max-width: 1000px;
  max-height: 900px;
  overflow-y: scroll;
  border: 1px solid ${colors.lightGray};
  padding: 10px 20px;
  margin-top: 60px;
  pre {
    white-space: pre-wrap; /* or pre-line */
  }
`;

export default function AlertHolidayEmailPreview() {

    const { id } = useParams<RouteParams>(); // this should be alertItemId
    const { data, refetch } = useAlert(`${BASE_URL}alerts-all/${id}`);
    const { data: jobs, refetch: refetchJobs } = useAlertJobs(`${BASE_URL}alert-jobs/${id}`);

    const paragraphStyle = {
        margin: 0,
        fontSize: '18px',
        color: '#333',
        lineHeight: 1.5
    };

    const getNewsItem = (alertNewsItem: any) => {
        if (alertNewsItem) {
            return `<tr>
                        <td style="padding: 20px 20px;">
                            <h1 style="margin: 0; font-size: 24px; color: #333;">${alertNewsItem.title || ''}</h1>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 0 20px; text-align: center;">
                            <a href="${alertNewsItem.link || ''}" target="_blank">
                                <img src="https://${alertNewsItem && alertNewsItem.image || ''}" alt="News Image" style="width: ${alertNewsItem && getImageWidth(alertNewsItem.imageWidth)} height: auto;">
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 0 20px 25px 20px;">
                            <div class="body-text" style="margin: 20px 0 0; font-size: 17px; color: #333; line-height: 1.2; font-weight: normal;">${alertNewsItem.body || ''}</div>
                            <a class="learnMoreButton" href="${alertNewsItem.link || ''}" target="_blank" style="display: inline-block; margin-top: 5px; font-size: 17px; color: #154F91; text-decoration: none; border-radius: 10px; border: 1px solid #154F91; padding: 4px 11px;">Learn More</a>
                        </td>
                    </tr>`
        } else {
            return '';
        }
    }

    const showDivider = () => {
        return `<tr>
                    <td style="padding: 0; text-align: center;">
                        <div style="background-color: #ccc; height: 1px;"></div>
                    </td>
                </tr>`;
    }

    const getBodyText = (alertItem: any) => {
        if (alertItem) {
            return `<tr>
                        <td style="padding: 0 20px 25px 20px;">
                            <div class="body-text" style="margin: 20px 0 0; font-size: 17px; color: #333; line-height: 1.2; font-weight: normal;">${alertItem.body || ''}</div>
                            <a class="learnMoreButton" href="${alertItem.link || ''}" target="_blank" style="display: inline-block; margin-top: 5px; font-size: 17px; color: #154F91; text-decoration: none; border-radius: 10px; border: 1px solid #154F91; padding: 4px 11px;">Learn More</a>
                        </td>
                    </tr>`
        } else {
            return '';
        }
    }

    const checkForPollAnswer = (answerNumber: number) => {

        if (!data?.alertPoll) {
            return '';
        }

        if (answerNumber === 1 && data?.alertPoll.answer1) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertPoll.answer1}</p>`;
        }
        if (answerNumber === 2 && data?.alertPoll.answer2) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertPoll.answer2}</p>`;
        }
        if (answerNumber === 3 && data?.alertPoll.answer3) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertPoll.answer3}</p>`;
        }
        if (answerNumber === 4 && data?.alertPoll.answer4) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertPoll.answer4}</p>`;
        }
        if (answerNumber === 5 && data?.alertPoll.answer5) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertPoll.answer5}</p>`;
        }
        if (answerNumber === 6 && data?.alertPoll.answer6) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertPoll.answer6}</p>`;
        }
        return '';
    };

    const checkForSurveyAnswer = (answerNumber: number) => {
        if (!data?.alertSurvey) {
            return '';
        }
        if (answerNumber === 1 && data?.alertSurvey.answer1) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertSurvey.answer1}</p>`;
        }
        if (answerNumber === 2 && data?.alertSurvey.answer2) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertSurvey.answer2}</p>`;
        }
        if (answerNumber === 3 && data?.alertSurvey.answer3) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertSurvey.answer3}</p>`;
        }
        if (answerNumber === 4 && data?.alertSurvey.answer4) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertSurvey.answer4}</p>`;
        }
        if (answerNumber === 5 && data?.alertSurvey.answer5) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertSurvey.answer5}</p>`;
        }
        if (answerNumber === 6 && data?.alertSurvey.answer6) {
            return `<p style="margin: 0; font-size: 18px; color: #333; line-height: 1.5;">► ${data?.alertSurvey.answer6}</p>`;
        }
        return '';
    };

    const getPartner = (adIndexPosition: number) => {
        if (data?.alertAds[adIndexPosition] && data?.alertAds[adIndexPosition].image) {
            if (data?.alertAds[adIndexPosition].micrositeLink) {
                return `
                    <tr>
                        <td style="padding: 20px; text-align: center;">
                            <a href="${data?.alertAds[adIndexPosition].link}" target="_blank">
                                <img src="https://${data?.alertAds[adIndexPosition].image}" alt="Newsletter Image" width="485" style="max-width: 485px; width: 100%; height: auto;">
                            </a>
                            <div style="font-size: 11px; color: #dddddd;">ADVERTISMENT</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 0px 20px 20px 20px; text-align: right; vertical-align: middle;">
                            <img src="https://dir80cd32z1m2.cloudfront.net/email/images/door.png" style="vertical-align: middle; line-height: 0;" />&nbsp;
                            <a href="${data?.alertAds[adIndexPosition].micrositeLink}" target="_blank" style="color: #76777a; vertical-align: middle;">View Microsite</a>
                        </td>
                    </tr>
                `
            } else {
                return `
                    <tr>
                        <td style="padding: 20px; text-align: center;">
                            <a href="${data?.alertAds[adIndexPosition].link}" target="_blank">
                                <img src="https://${data?.alertAds[adIndexPosition].image}" alt="Newsletter Image" width="485" style="max-width: 485px; width: 100%; height: auto;">
                            </a>
                            <div style="font-size: 11px; color: #dddddd;">ADVERTISMENT</div>
                        </td>
                    </tr>
                `
            }
        } else {
            return '';
        }
    }

    const getSpeaker = () => {
        if (data && data.alertEvent && data.alertEvent.speaker) {
            return `
                <p style="margin: 5px 0 0; font-size: 16px; color: #777; line-height: 1.5;">${data?.alertEvent && data?.alertEvent.speaker || ''}</p>
            `
        } else {
            return '';
        }
    }

    const getCareers = () => {
        if (jobs && jobs.length > 0) {
            return jobs.map((item, index) => {
                const job = jobs?.find(job => job.position === index + 1);
                return `<tr>
                <td style="padding: 0 0 10px 0;">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                        <tr>
                            <td style="padding: 0 20px 20px 20px; text-align: left; max-width: 520px;">
                                <a href="${job?.job?.link}" target="_blank" style="text-decoration: none; color: #154F91;"><h3 style="margin: 0 0 2px 0; font-size: 20px;">${job?.job?.jobTitle}</h3></a>
                                <div style="margin: 0; font-size: 18px; color: #333;">${job?.job?.company}</div>
                                <div style="margin: 3px 0 0 0; font-size: 16px; color: #666;">${job?.job?.location}</div>
                                <div class="body-text" style="margin: 15px 0 0; font-size: 17px; color: #333; line-height: 1.2; font-weight: normal;">${job?.job?.description}</div>
                                <a class="learnMoreButton" href="${job?.job?.link}" target="_blank" style="display: inline-block; margin-top: 15px; margin-bottom: 10px; color: #154F91; font-size: 17px; text-decoration: none; border-radius: 10px; border: 1px solid #154F91; padding: 4px 11px;">Learn More</a>
                            </td>
                            <td style="padding: 0 20px 20px 20px; text-align: right;">
                                <a href="${job?.job?.link}" target="_blank">
                                    <img src="https://${job?.job?.companyLogo}" alt="Service Image" width="140" style="max-width: 140px; height: auto;">
                                </a>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>`;
            }).join(''); // Use join to concatenate the array into a single string
        }

        // If jobs is undefined or has a length of 0, you might want to return a default value or handle it accordingly
        return '';
    }


    const getImageWidth = (imageWidth?: string | number) => {
        if (imageWidth !== undefined) {
            // Convert to string if it's a number
            const widthValue = typeof imageWidth === 'number' ? `${imageWidth}px` : imageWidth;
            return `${widthValue};`;
        } else {
            return '100%;';
        }
    }

    const htmlCode = `<!DOCTYPE html>
<html>
<head>
    <title>Aspen Alert</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
     <style type="text/css">
        @media (min-width: 600px) {
            .body-text {
			    // font-size: 16px !important;
		    }
            .learnMoreButton {
                // font-size: 18px;
            }
        }
        .paragraph p {
            margin: 0;
        }
    </style>
</head>
<body style="margin: 0; padding: 20px 0; font-family: Helvetica, sans-serif; background-color: #e9e9e9;">

    <!-- Container Table -->
    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
        <tr>
            <td align="center" style="padding: 0">
                <!--[if (gte mso 9)|(IE)]>
                <table role="presentation" align="center" cellspacing="0" cellpadding="0" border="0" width="600">
                <tr>
                <td align="center" valign="top" width="600">
                <![endif]-->
                <table role="presentation" align="center" cellspacing="0" cellpadding="0" border="0" width="100%" style="max-width: 600px; background-color: #ffffff;">
                    <tr>
                        <td style="padding: 10px 20px;">
                            <img src="https://mcusercontent.com/7796449412bb01b7d9f26f16a/images/0e5efb47-98cd-4b35-add8-4e1d7255cafd.png" alt="Newsletter Image" style="width: 100%; height: auto;">
                        </td>
                    </tr>
                    <!-- External Nav -->
                    <tr>
                        <td bgcolor="#154f91" style="text-align: center; font-family: Helvetica, sans-serif; padding: 5px 10px; background-color: #154f91;">

                            <table>
                                <tr>
                                    <td valign="middle">
                                        <a href="https://www.aspenalert.com/atheneum" target="_blank" style="text-decoration: none; color: #fff; font-size: 16px; display: inline-block; vertical-align: middle; line-height: 20px; padding: 0">
                                            <img src="https://dir80cd32z1m2.cloudfront.net/email/images/nav-images/atheneum-link.png" alt="Atheneum" width="122" style="display: inline-block; height: auto; width: 122px; vertical-align: middle;">
                                        </a>
                                    </td>
                                    <td valign="middle">
                                        <a href="https://www.aspenalert.com" target="_blank" style="text-decoration: none; color: #fff; font-size: 16px; display: inline-block; vertical-align: middle; line-height: 20px; padding: 0">
                                            <img src="https://dir80cd32z1m2.cloudfront.net/email/images/nav-images/podcast-link.png" alt="Podcast" width="105" style="display: inline-block; height: auto; width: 105px; vertical-align: middle;">
                                        </a>
                                    </td>
                                    <td valign="middle">
                                        <a href="https://www.aspenalert.com/marketplace" target="_blank" style="text-decoration: none; color: #fff; font-size: 16px; display: inline-block; vertical-align: middle; line-height: 20px; padding: 0">
                                            <img src="https://dir80cd32z1m2.cloudfront.net/email/images/nav-images/marketplace-link.png" alt="Marketplace" width="154" style="display: inline-block; height: auto; width: 154px; vertical-align: middle;">
                                        </a>
                                    </td>
                                    <td valign="middle">
                                        <a href="https://www.aspenalert.com/events-calendar" target="_blank" style="text-decoration: none; color: #fff; font-size: 16px; display: inline-block; vertical-align: middle; line-height: 20px; padding: 0">
                                            <img src="https://dir80cd32z1m2.cloudfront.net/email/images/nav-images/events-link.png" alt="Events" width="100" style="display: inline-block; height: auto; width: 100px; vertical-align: middle;">
                                        </a>
                                    </td>
                                    <td valign="middle">
                                        <a href="https://www.aspenalert.com/career-center" target="_blank" style="text-decoration: none; color: #fff; font-size: 16px; display: inline-block; vertical-align: middle; line-height: 20px; padding: 0">
                                            <img src="https://dir80cd32z1m2.cloudfront.net/email/images/nav-images/careers-link.png" alt="Careers" width="119" style="display: inline-block; height: auto; width: 119px; vertical-align: middle;">
                                        </a>
                                    </td>
                                </tr>
                            </table>

                        </td>
                    </tr>
                    <!-- External Nav End -->
                    <tr>
                        <td style="background-color: #4771A7; padding: 14px 20px; text-align: right; border-bottom: 2px solid #7b99be;">
                            <div style="margin: 0; font-size: 17px; color: #ffffff;">Issue # ${data?.alertItem.issue} | ${moment(data?.alertItem.date).format('MMMM Do, YYYY')}</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color: #FFB71B; padding: 15px 20px; text-align: left;">
                            <h2 style="margin: 0; font-size: 20px; color: #ffffff;">News Alert</h2>
                        </td>
                    </tr>
                    <!-- alert news -->
                    ${getNewsItem(data?.alertNews[0])}
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    ${getNewsItem(data?.alertNews[1])}

                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    ${getNewsItem(data?.alertNews[2])}
                    <!-- end alert news -->
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                    ${getPartner(0)}
                    <!-- partner -->
                    <!-- quote alert section -->
                    <tr>
                        <td style="background-color: #154F91; padding: 15px 20px; text-align: left;">
                            <h2 style="margin: 0; font-size: 20px; color: #ffffff;">Quote Alert</h2>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 20px; text-align: center;">
                            <a href="${data?.alertQuote && data?.alertQuote.link || ''}" target="_blank">
                                <img src="https://${data?.alertQuote && data?.alertQuote.image || ''}" alt="Newsletter Image" style="width: ${data?.alertQuote && getImageWidth(data?.alertQuote.imageWidth)} height: auto;">
                            </a>
                        </td>
                    </tr>
                    <!-- end quote alert section -->
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- OTD section -->
                    <tr>
                        <td style="background-color: #154F91; padding: 15px 20px; text-align: left;">
                            <h2 style="margin: 0; font-size: 20px; color: #ffffff;">On This Day Alert</h2>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#ffffff" style="padding: 20px; border-radius: 10px;">
                            <h1 style="margin: 0; font-size: 24px; color: #333;">${data?.alertOtd && data?.alertOtd.title || ''}</h1>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 0 20px; text-align: center;">
                            <a href="${data?.alertOtd && data?.alertOtd.imageLink || ''}" target="_blank">
                                <img src="https://${data?.alertOtd && data?.alertOtd.image || ''}" alt="Newsletter Image" style="width: ${data?.alertOtd && getImageWidth(data?.alertOtd.imageWidth)} height: auto;">
                            </a>
                        </td>
                    </tr>
                    ${getBodyText(data?.alertOtd)}
                    <!-- End OTD section -->
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                    ${getPartner(1)}
                    <!-- partner -->
                    <!-- TED section -->
                    <tr>
                        <td style="background-color: #154F91; padding: 15px 20px; text-align: left;">
                            <h2 style="margin: 0; font-size: 20px; color: #ffffff;">TED Alert</h2>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#ffffff" style="padding: 20px; border-radius: 10px;">
                            <h1 style="margin: 0; font-size: 24px; color: #333;">${data?.alertTed && data?.alertTed.title || ''}</h1>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 0 20px; text-align: center;">
                            <a href="${data?.alertTed && data?.alertTed.imageLink || ''}" target="_blank">
                                <img src="https://${data?.alertTed && data?.alertTed.image || ''}" alt="Newsletter Image" style="width: ${getImageWidth(data?.alertTed.imageWidth)} height: auto;">
                            </a>
                        </td>
                    </tr>
                    ${getBodyText(data?.alertTed)}
                    <!-- TED end section -->
        
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                        ${getPartner(2)}
                    <!-- end partner -->
                    
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                        ${getPartner(3)}
                    <!-- end partner -->
                    
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                        ${getPartner(4)}
                    <!-- end partner -->
                    
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                        ${getPartner(5)}
                    <!-- end partner -->
                    
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                        ${getPartner(6)}
                    <!-- end partner -->
                    
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                        ${getPartner(7)}
                    <!-- end partner -->
                    
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                        ${getPartner(8)}
                    <!-- end partner -->
                    <!-- humor alert section -->
                    <tr>
                        <td style="background-color: #154F91; padding: 15px 20px; text-align: left;">
                            <h2 style="margin: 0; font-size: 20px; color: #ffffff;">Humor Alert</h2>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 20px; text-align: center;">
                            <a href="${data?.alertHumor && data?.alertHumor.link || ''}" target="_blank">
                                <img src="https://${data?.alertHumor && data?.alertHumor.image || ''}" alt="Newsletter Image" style="width: 100%; height: auto; margin: 0; padding: 0 !important; margin: 0 !important;">
                            </a>
                            <div class="paragraph" style="font-size: 12px; color: #8b8c8f; padding: 0 !important; margin: 0 !important; text-align: left; display: block;">
                                ${data?.alertHumor.citation}
                            </div>
                        </td>
                    </tr>
                    <!-- end humor alert section -->
                    <!-- section -->
                    <tr>
                        <td style="background-color: #154F91; padding: 15px 20px; text-align: left;">
                            <h2 style="margin: 0; font-size: 20px; color: #ffffff;">Fact Alert</h2>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#ffffff" style="padding: 20px; border-radius: 10px;">
                            <h1 style="margin: 0; font-size: 24px; color: #333;">${data?.alertFact && data?.alertFact.title || ''}</h1>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 0 20px; text-align: center;">
                            <a href="${data?.alertFact && data?.alertFact.imageLink || ''}" target="_blank">
                                <img src="https://${data?.alertFact && data?.alertFact.image || ''}" alt="Newsletter Image" style="width: ${data?.alertFact && getImageWidth(data?.alertFact.imageWidth)} height: auto;">
                            </a>
                            <div class="paragraph" style="font-size: 12px; color: #8b8c8f; padding: 0 !important; margin: 0 !important; text-align: left; display: block;">
                                ${data?.alertFact.citation}
                            </div>
                        </td>
                    </tr>
                    ${getBodyText(data?.alertFact)}
                    <!-- end section -->
                    <!-- divider -->
                    ${showDivider()}
                    <!-- end divider -->
                    <!-- partner -->
                        ${getPartner(9)}
                    <!-- end partner -->
                    <!-- career section -->
                    <tr>
                        <td style="background-color: #154F91; padding: 15px 20px; text-align: left;">
                            <h2 style="margin: 0; font-size: 20px; color: #ffffff;">Career Alert</h2>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 0 0 20px 0;">
                            <img src="https://mcusercontent.com/7796449412bb01b7d9f26f16a/images/16949739-451d-3eea-4d1e-686ff31eebac.png" alt="Full Width Image" style="width: 100%; height: auto;">
                        </td>
                    </tr>
                    ${getCareers()}
                    <tr>
                        <td style="padding: 0px 20px 40px 20px; text-align: right; vertical-align: middle;">
                            <img src="https://dir80cd32z1m2.cloudfront.net/email/images/briefcase.png" style="vertical-align: middle; line-height: 0;" />&nbsp;
                            <a style="color: #76777a; vertical-align: middle;" href="https://aspenalert.com/career-center">Visit Careers</a>
                        </td>
                    </tr>
                    <!-- end career section -->
                    <!-- Footer Section -->
                    <tr>
                        <td bgcolor="#bcbbba" style="padding: 0;">
                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                <tr>
                                    <td align="center">
                                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="max-width: 600px; margin: 0 auto;">
                                            <tr>
                                                <td style="padding: 20px;">
                                                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                        <tr>
                                                            <td style="text-align: center; vertical-align: top;">
                                                                <a href="https://www.aspenalert.com/contact-us" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; margin-top: 5px; font-size: 14px; font-weight: bold; ">Aspen Media, Inc.</a>
                                                                <a href="https://www.aspenalert.com/contact-us" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Contact</a>
                                                                <a href="#" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Newsletter Advertising</a>
                                                                <a href="#" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Email Marketing</a>
                                                                <a href="#" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Podcast Sponsorship</a>

                                                                <a href="https://www.aspenalert.com/account" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px; font-weight: bold; margin-top: 20px;">My Account</a>
                                                                <a href="https://aspenalert.com/account" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Login</a>
                                                                <a href="https://aspenalert.com/account/custom-notifications" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Custom Notifications</a>
                                                                <a href="https://aspenalert.com/favorites" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Favorites</a>
                                                                <a href="https://aspenalert.us8.list-manage.com/subscribe?u=7796449412bb01b7d9f26f16a&id=a13a55a569" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Subscribe</a>

                                                            </td>
                                                            <td style="width: 20px;"></td><!-- Spacer -->
                                                            <td style="text-align: center; vertical-align: top;">
                                                                <a href="https://aspenalert.com/" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; margin-top: 5px; font-size: 14px; font-weight: bold; ">Aspen Alert</a>
                                                                <a href="https://us8.campaign-archive.com/home/?u=7796449412bb01b7d9f26f16a&id=a13a55a569" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Newsletter</a>
                                                                <a href="https://aspenalert.com/atheneum" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px; margin-top: 5px; ">Atheneum</a>
                                                                <a href="https://aspenalert.com/marketplace" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Marketplace</a>
                                                                <a href="https://aspenalert.com/events-calendar" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Events Calendar</a>
                                                                <a href="https://aspenalert.com/career-center" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px; margin-top: 5px; font-size: 14px;">Career Center</a>

                                                                <a href="https://www.aspenalert.com/contribute" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px;  font-size: 14px; font-weight: bold; margin-top: 20px;">Contribute</a>
                                                                <a href="https://www.aspenalert.com/contribute/atheneum-submission" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Atheneum</a>
                                                                <a href="https://www.aspenalert.com/contribute/event-submission" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Events Calendar</a>
                                                                <a href="https://www.aspenalert.com/contribute/career-center-submission" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Career Center</a>
                                                            </td>
                                                            <td style="width: 20px;"></td><!-- Spacer -->
                                                            <td style="text-align: center; vertical-align: top;">
                                                                <a href="https://www.aspenalert.com/privacy-center" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; margin-top: 8px; font-size: 14px; font-weight: bold; ">Privacy Center</a>
                                                                <a href="https://www.aspenalert.com/privacy-center/privacy" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Privacy Policy</a>
                                                                <a href="https://www.aspenalert.com/privacy-center/terms" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Terms of Use</a>
                                                                <a href="https://www.aspenalert.com/privacy-center/personal-info" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 6px; font-size: 14px;">Do Not Sell My Personal Info</a>
                                                                <!-- social links -->
                                                                <table cellpadding="0" cellspacing="0" class="es-table-not-adapt es-social" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:100%;">
                                                                    <tr>
                                                                        <td align="center">
                                                                            <a href="#" style="color: #154F91; text-decoration: none; display: block; margin-bottom: 8px; font-size: 14px; font-weight: bold; margin-top: 20px;">Follow Us On Social Media</a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center">
                                                                            <table cellpadding="0" cellspacing="0" class="es-table-not-adapt es-social" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;">
                                                                                <tr>
                                                                                    <td align="center" valign="top" style="padding:0;Margin:0;padding-right:10px">
                                                                                        <a target="_blank" href="https://www.linkedin.com/company/aspen-brook" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#154F91;font-size:14px"><img src="https://hbxliz.stripocdn.email/content/assets/img/social-icons/circle-colored/linkedin-circle-colored.png" alt="In" title="Linkedin" width="32" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"></a>
                                                                                    </td>
                                                                                    <td align="center" valign="top" style="padding:0;Margin:0">
                                                                                        <a target="_blank" href="https://www.instagram.com/aspen_media_inc/" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#154F91;font-size:14px">
                                                                                            <img src="https://hbxliz.stripocdn.email/content/assets/img/social-icons/circle-colored/instagram-circle-colored.png" alt="Ig" title="Instagram" width="32" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic">
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                               <!-- Social links end -->
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <table>
                                            <tr>
                                                <td>
                                                    <span style="color: #666; text-decoration: none; text-align: center; display: block; margin-bottom: 15px; font-size: 14px;">Powered by Aspen Media, Inc. &copy; Copyright ${moment().format('YYYY')}</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <!-- End Footer Section -->
                    <!-- End Footer Section -->
                </table>
                <!--[if (gte mso 9)|(IE)]>
                </td>
                </tr>
                </table>
                <![endif]-->
            </td>
        </tr>
    </table>

</body>
</html>

`;


    return (
        <>
            <StyledContainer>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
                </div>
            </StyledContainer>
            <StyledContainer>
                <div>
                    <CopyToClipboardButton textToCopy={htmlCode} />
                </div>
                <pre>
                    <code>{htmlCode}</code>
                </pre>
            </StyledContainer>
        </>
    )
}