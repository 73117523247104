import React, { Component } from 'react';
import { connect } from "react-redux";
import { history } from "../../../routers/AppRouter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify/index';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import '../../../styles/preview-modal.css';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../styles/post-editor.css';
import MainButton from '../../../components/MainButton';

// redux
import { startCreateUser } from '../../../actions/users';

const PageContainer = styled.div`
  padding: 20px 40px;
`;

const MainContentContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`

`;

const TitleFeaturesSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledIconButtons = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledSidebar = styled.div`
  min-width: 250px;
  padding: 15px;
  margin: 0 0 0 20px;
  background: ${colors.lightestGray};
`;

const SidebarSection = styled.div`
  padding: 15px 0;
`;

const SidebarSectionBackground = styled.div`
  background: ${colors.white};
  padding: 5px;
  max-height: 160px;
  overflow: scroll;
`;

const CheckboxStyled = styled.div`
  padding: 5px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonMargin = styled.div`
  margin-right: 15px;
`;

const StyledHeading = styled.div`
  padding: 10px 0;
  font-size: 40px;
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const StyledTextArea = styled.textarea`
  width: 400px;
  font-size: 16px;
  padding: 10px;
`;

const RightSideHeading = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  padding: 30px 0 20px 0;
  color: ${colors.blue};
  font-size: 22px;
`;

const StyledTitle = styled.div`
  padding: 20px 0 10px 0;
`;

const DisplayTitleCheck = styled.div`
  text-align: right;
  padding: 10px 0 15px 0;
  input {
    margin-right: 7px;
  }
`;

const FeaturedImageSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PreviewSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const StyledImageSection = styled.div`
  display: flex;
  align-items: center;
`;

const ImagePreview = styled.img`
  max-height: 100px;
  max-width: 150px;
  object-fit: cover;
  margin: 0 15px;
  border: 1px solid ${colors.lightGray};
`;

const ImagePlaceholderStyled = styled.div`
  width: 250px;
  height: 60px;
  background: ${colors.lightestGray};
  color: ${colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin: 15px;
`;

const DisplaySection = styled.div`
  padding-left: 30px;
`;

const FeaturedImageLinkSection = styled.div`
  padding-left: 30px;
  input {
    width: 350px;
  }
`;

const RadioSection = styled.div`
  max-width: 150px;
  label {
    display: block;
    padding: 5px 0;
  }
`;

const LinkInput = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const CategorySection = styled.div`
  margin: 40px 0;
  padding: 20px 0;
  border-top: 1px solid ${colors.lightGray};
`;

const CategorySectionHeading = styled.div`
  font-size: 25px;
  color: ${colors.blue}
`;

const SchedulePopup = styled.div`
  position: absolute;
  padding-top: 10px;
  display: ${props => props.showCalendar ? 'block' : 'none'};
  z-index: 100;
`;

const ImageSelection = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
`;

const FileRow = styled.div`
  position: relative;
  button {
    z-index: 1;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
`;

const MultipleImagePreview = styled.div`
  display: flex;
  padding: 0 10px;
`;

const ModalPreviewTitle = styled.div`
  position: absolute;
  background: ${colors.darkGray};
  color: ${colors.white};
  left: 0;
  text-align: center;
  top: -56px;
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
`;

const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  z-index: 100;

  @media (min-width: 720px) {
    width: ${props => props.modalWidth ? props.modalWidth : '442px'};
    min-height: 495px;
  }
`;

const ModalHeading = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background: ${colors.lightGray};
  color: ${colors.white};
  text-align: center;
  font-weight: bold;
  padding: 5px;
`;

const ModalFeaturedImage = styled.div`
  padding: 20px 0;
  text-align: center;
  img {
    max-width: 300px;
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  color: ${colors.black};
  font-weight: bold;
  padding-top: 15px;
`;

const ModalDetails = styled.div`
  padding-top: 10px;
  padding: 0;
  opacity: 0.8;
  font-size: 15px;
  color: ${colors.black};
  // p {
  //   padding: 0;
  //   line-height: 15px;
  // }
`;

const ModalBody = styled.div`
  padding: 20px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
`;

const ModalDocuments = styled.div`
  display: flex;
  padding: 15px 0;
  h2 {
    font-weight: bold;
    font-size: 15px;
    color: ${colors.darkGray};
    padding: 0 15px 0 0;
    margin: 0;
  }
  h3 {
    font-size: 13px;
    color: ${colors.darkGray};
    padding: 5px 0;
    margin: 0;
  }
  img {
    margin: 0 5px;
    border: 1px solid ${colors.lightGray};
    max-height: 100px;
    max-width: 150px;
    object-fit: cover;
  }
`;

const ModalArrowRight = styled.button`
  position: absolute;
  background: none;
  border: none;
  right: -50px;
  top: 42%;
  cursor: pointer;
  outline: none;
`;

const ModalArrowLeft = styled.button`
  position: absolute;
  background: none;
  border: none;
  left: -50px;
  top: 42%;
  cursor: pointer;
  transform: rotate(180deg);
  outline: none;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf, video/mp4, video/mpg, video/mov, video/wmv, video/avi, video/mpeg4';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

class User extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    }
  }

  componentDidMount() {
    const jobId = this.props.match.params.id;
    if (jobId) {
      this.props.getJob(jobId);
      this.setState(() => ({
        editingJobId: parseInt(jobId),
      }));
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.user !== prevProps.user) {
      this.setState(() => ({
        email: this.props.user.email,
        password: '',
      }));
    }

  }

  clearState = () => {
    this.setState(() => ({
      email: '',
      password: '',
    }), history.push('/user-list'));
  };

  handleEmailChange = (email) => {
    this.setState(() => ({ email }));
  };

  handlePasswordChange = (password) => {
    this.setState(() => ({ password }));
  };

  createUser = () => {

    if (this.state.email && this.state.password) {
      let userInfo = {
        email: this.state.email,
        password: this.state.password,
      };

      this.props.createUser(userInfo);
      this.clearState();
    } else {
      //validation errors
      toast.error('Please fil out all required fields');
    }
  };

  render() {
    return (
      <PageContainer>
        <HeadingContainer>
          <StyledHeading>Create a User</StyledHeading>
          <RightSideHeading>
            <MainButton title="Create User" dark={true} onClick={() => this.createUser()} />
          </RightSideHeading>
        </HeadingContainer>
        <MainContentContainer>
          <MainContent>

            <StyledTitle>Email</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.email}
              onChange={(e) => this.handleEmailChange(e.target.value)}
            />

            <StyledTitle>Password</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.password}
              onChange={(e) => this.handlePasswordChange(e.target.value)}
            />

          </MainContent>

        </MainContentContainer>
        <ToastContainer />
      </PageContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    // post: state.posts.singlePost,
    // job: state.jobs.singleJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // getJob: (id) => dispatch(getJob(id)),
  createUser: (userInfo) => dispatch(startCreateUser(userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
