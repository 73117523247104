import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import locationReducer from "../reducers/location";
import signUpReducer from "../reducers/sign-up";
import supportReducer from "../reducers/support";
import signInReducer from "../reducers/sign-in";
import accountInfoReducer from "../reducers/account-info";
import profileReducer from "../reducers/profile";
import messagingReducer from "../reducers/messaging";
import resetPasswordReducer from "../reducers/reset-password";
import uploadReducer from "../reducers/upload";
import publicProfileReducer from "../reducers/public-profile";
import postsReducer from '../reducers/posts';
import postTypesReducer from '../reducers/post-types';
import categoriesReducer from '../reducers/categories';
import micrositeReducer from '../reducers/microsite';
import micrositesReducer from '../reducers/microsites';
import micrositeSubmissionReducer from '../reducers/microsite-submission';
import micrositeSettingReducer from '../reducers/microsite-settings';
import micrositeCategoriesReducer from '../reducers/microsite-categories';
import micrositeKeywordsReducer from '../reducers/microsite-keywords';
import postCategoriesReducer from '../reducers/post-categories';
import contactFormsReducer from '../reducers/contact-forms';
import jobsReducer from '../reducers/jobs';
import documentsReducer from '../reducers/documents';
import eventsReducer from '../reducers/events';
import adsReducer from '../reducers/ads';
import mediaImagesReducer from '../reducers/media-images';
import mediaTagsReducer from '../reducers/media-tags';
import uploadMediaLibraryReducer from '../reducers/media-library-upload';
import usersReducer from '../reducers/users';
import micrositeGalleryReducer from '../reducers/microsite-gallery';
import postKeywordsReducer from '../reducers/post-keywords';
import postReducer from '../reducers/post';
import alertsReducer from "../reducers/alerts";
import clientsReducer from '../reducers/clients';
import bulkUpdateReducer from '../reducers/bulk-update';
import bulkUpdatePostMicrositeReducer from '../reducers/bulk-update-post-microsite';
import alertItemsReducer from "../reducers/alerts/alert-items";
import alertNewsReducer from '../reducers/alerts/alert-news';
import alertRegulatoryReducer from '../reducers/alerts/alert-regulatory';
import alertQuoteReducer from '../reducers/alerts/alert-quote';
import alertFactReducer from '../reducers/alerts/alert-fact';
import alertOtdReducer from '../reducers/alerts/alert-otd';
import alertPatentReducer from '../reducers/alerts/alert-patent';
import alertBioprocessReducer from '../reducers/alerts/alert-bioprocess';
import alertTedReducer from '../reducers/alerts/alert-ted';
import alertHumorReducer from '../reducers/alerts/alert-humor';
import alertDiscoveryReducer from '../reducers/alerts/alert-discovery';
import alertProjectReducer from '../reducers/alerts/alert-project';
import alertUpstreamReducer from '../reducers/alerts/alert-upstream';
import alertDownstreamReducer from '../reducers/alerts/alert-downstream';
import alertFillReducer from '../reducers/alerts/alert-fill';
import alertSpotlightReducer from '../reducers/alerts/alert-spotlight';
import alertSolutionReducer from '../reducers/alerts/alert-solution';
import alertPollReducer from '../reducers/alerts/alert-poll';
import alertSurveyReducer from '../reducers/alerts/alert-survey';
import alertJobReducer from '../reducers/alerts/alert-job';
import alertEventReducer from '../reducers/alerts/alert-event';
import alertPreviousPollReducer from '../reducers/alerts/alert-previous-poll';
import alertPreviousSurveyReducer from '../reducers/alerts/alert-previous-survey';
import alertAdsReducer from '../reducers/alerts/alert-ads';
import alertItemReducer from '../reducers/alerts/alert-item';
import statsEmailReducer from '../reducers/portal/stats-email';
import emailContentStatReducer from '../reducers/portal/email-content-stat';
import emailLeadStatReducer from '../reducers/portal/email-lead-stat';
import userEditingReducer from '../reducers/user-editing';

//using devtools with thunk as middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  return createStore(
    combineReducers({
      posts: postsReducer,
      postTypes: postTypesReducer,
      categories: categoriesReducer,
      postCategories: postCategoriesReducer,
      location: locationReducer,
      signUp: signUpReducer,
      resetPassword: resetPasswordReducer,
      userInfo: signInReducer,
      support: supportReducer,
      accountInfo: accountInfoReducer,
      profile: profileReducer,
      publicProfile: publicProfileReducer,
      message: messagingReducer,
      upload: uploadReducer,
      microsite: micrositeReducer,
      microsites: micrositesReducer,
      micrositeSubmission: micrositeSubmissionReducer,
      micrositeSetting: micrositeSettingReducer,
      micrositeCategories: micrositeCategoriesReducer,
      micrositeKeywords: micrositeKeywordsReducer,
      contactForms: contactFormsReducer,
      jobs: jobsReducer,
      documents: documentsReducer,
      events: eventsReducer,
      ads: adsReducer,
      mediaImages: mediaImagesReducer,
      mediaTags: mediaTagsReducer,
      uploadMediaLibrary: uploadMediaLibraryReducer,
      users: usersReducer,
      clients: clientsReducer,
      micrositeGallery: micrositeGalleryReducer,
      postKeywords: postKeywordsReducer,
      post: postReducer,
      alerts: alertsReducer,
      bulkUpdate: bulkUpdateReducer,
      bulkUpdatePostMicrosite: bulkUpdatePostMicrositeReducer,
      alertItems: alertItemsReducer,
      alertItem: alertItemReducer,
      alertNews: alertNewsReducer,
      alertRegulatory: alertRegulatoryReducer,
      alertDiscovery: alertDiscoveryReducer,
      alertProject: alertProjectReducer,
      alertQuote: alertQuoteReducer,
      alertHumor: alertHumorReducer,
      alertFact: alertFactReducer,
      alertTed: alertTedReducer,
      alertOtd: alertOtdReducer,
      alertPatent: alertPatentReducer,
      alertUpstream: alertUpstreamReducer,
      alertDownstream: alertDownstreamReducer,
      alertBioprocess: alertBioprocessReducer,
      alertFill: alertFillReducer,
      alertSpotlight: alertSpotlightReducer,
      alertSolution: alertSolutionReducer,
      alertPoll: alertPollReducer,
      alertSurvey: alertSurveyReducer,
      alertJob: alertJobReducer,
      alertEvent: alertEventReducer,
      alertPreviousPoll: alertPreviousPollReducer,
      alertPreviousSurvey: alertPreviousSurveyReducer,
      alertAds: alertAdsReducer,
      statsEmail: statsEmailReducer,
      emailContentStat: emailContentStatReducer,
      emailLeadStat: emailLeadStatReducer,
      userEditing: userEditingReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
};
