import axiosInstance from '../utility/axios';

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});


export const startGetUserEditing = () => ({
    type: 'START_GET_USER_EDITING',
});

export const getUserEditingSuccess = (user) => ({
    type: 'GET_USER_EDITING_SUCCESS',
    user
});

export const getUserEditing = () => {
    return (dispatch) => {
        dispatch(startGetUserEditing());
        axiosInstance.get(`user-admin/1`)
            .then((response) => {
                dispatch(getUserEditingSuccess(response.data));
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response) {
                    dispatch(showError(error.response.data.message));
                } else {
                    dispatch(showError('Network Error'));
                }
                console.log(error);
            });
    }
};

export const startUserEditingUpdate = () => ({
    type: 'START_USER_EDITING_UPDATE',
});

export const updateUserEditingSuccess = (user) => ({
    type: 'UPDATE_USER_EDITING_SUCCESS',
    user
});

export const clearUserEditingSuccess = () => ({
    type: 'UPDATE_USER_EDITING_CLEAR_SUCCESS'
});

export const updateUserEditing = (userDetails, userId) => {
    return (dispatch) => {
        dispatch(startUserEditingUpdate());
        axiosInstance.put(`user-admin/${userId}`, userDetails)
            .then((response) => {
                console.log('user updated: ', response.data);
                dispatch(updateUserEditingSuccess(response.data));
                setTimeout(() => {
                    dispatch(clearUserEditingSuccess());
                }, 1000)
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

