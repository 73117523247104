import React, { Component, useEffect, useState } from 'react';
import styled from "styled-components";
import { connect } from "react-redux";
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding } from '../../../../styles/form-styles.js';
import ABButton from '../../../../components/ABButton.js';
import DatePicker from 'react-datepicker';
import colors from '../../../../styles/colors.js';
import ABSelect from '../../../../components/ABSelect.js';
import { EmailStatsTypes } from '../stats-types.js';
import { createStatsEmail } from '../../../../actions/portal/stats-email.js';
import { ToastContainer, toast } from 'react-toastify';
import { history } from '../../../../routers/AppRouter.js';
import ABInputField from '../../../../components/ABInputField';
import { getAlertItems } from '../../../../actions/alert/alert-items.js';
import { AlertItem } from '../../alerts/alert-types.js';
import ABFormButton from '../../../../components/ABFormButton.js';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const TextButton = styled.div`
    cursor: pointer;
    margin-right: 5px;
    color: ${colors.blue};
`;

const DatePickerStyle = styled.div`
  input {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    min-width: 400px;
    margin-bottom: 20px;
    line-height: 25px;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 9px 10px 10px 10px;
  width: 450px;
  border: 1px solid ${colors.lightGray};
`;

interface EmailStatsProps {
    createStatsEmail: (statsEmail: EmailStatsTypes) => void;
    success?: string | null;
    error?: string | null;
    getAlerts: () => void;
    alerts: AlertItem[];
}

function EmailStatsForm({ createStatsEmail, success, error, getAlerts, alerts }: EmailStatsProps) {

    const [alertItemId, setAlertItemId] = useState('');
    const [sent, setSent] = useState('');
    const [delivered, setDelivered] = useState('');
    const [bounceRate, setBounceRate] = useState('');
    const [openRate, setOpenRate] = useState('');
    const [opens, setOpens] = useState('');
    const [clicks, setClicks] = useState('');
    const [ctr, setCtr] = useState('');
    const [ctor, setCtor] = useState('');
    const [deliveryRate, setDeliveryRate] = useState('');
    const [unsubscribeRate, setUnsubscribeRate] = useState('');
    const [spamRate, setSpamRate] = useState('');
    const [growthRate, setGrowthRate] = useState('');

    useEffect(() => {
        if (success) {
            history.push('./');
        }
        if (error) {
            toast.error('Error Creating Stat');
        }
    }, [success, error]);

    useEffect(() => {
        getAlerts();
    }, [])

    const onCreate = () => {

        if (alertItemId && sent && delivered && openRate && opens && clicks && ctr && ctor && bounceRate) {
            const emailStats: EmailStatsTypes = {
                alertItemId: parseInt(alertItemId, 10) || 0,
                sent: parseInt(sent, 10) || 0,
                delivered: parseInt(delivered, 10) || 0,
                openRate: parseInt(openRate, 10) || 0,
                opens: parseInt(opens, 10) || 0,
                clicks: parseInt(clicks, 10) || 0,
                ctr: parseInt(ctr, 10) || 0,
                ctor: parseInt(ctor, 10) || 0,
                bounceRate: parseInt(bounceRate, 10) || 0,
                deliveryRate: deliveryRate ? parseInt(deliveryRate, 10) : undefined,
                unsubscribeRate: unsubscribeRate ? parseInt(unsubscribeRate, 10) : undefined,
                spamRate: spamRate ? parseInt(spamRate, 10) : undefined,
                growthRate: growthRate ? parseInt(growthRate, 10) : undefined, // Set as undefined if empty
            };
            createStatsEmail(emailStats);
        } else {
            toast.error('Please fill out all fields.');
        }
    };

    const onCancel = () => {
        history.push('./');
    }

    // const handleDateChange = (date: Date | null) => {
    //     date!.setHours(1);
    //     date!.setMinutes(0);
    //     date!.setSeconds(0);
    //     setDate(date);
    // };

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) =>
        (e: React.ChangeEvent<HTMLInputElement>) => setter(e.currentTarget.value);

    const handleSelectChange =
        (setter: React.Dispatch<React.SetStateAction<string>>) =>
            (e: React.ChangeEvent<HTMLSelectElement>) =>
                setter(e.target.value);

    return (
        <PageWrapper>
            <HeadingContainer>
                <StyledHeading>Create Email Stats</StyledHeading>
            </HeadingContainer>

            <FormSubHeading>Alert Id:</FormSubHeading>
            {
                alerts && alerts.length > 0 &&
                <StyledSelect
                    name="alertItemId"
                    value={alertItemId}
                    onChange={handleSelectChange(setAlertItemId)}
                    placeholder="Enter Alert ID"
                >
                    <option value="">Select</option>
                    {alerts.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.issue} - {item.title}
                        </option>
                    ))}
                </StyledSelect>
            }

            <FormSubHeading>Sent:</FormSubHeading>
            <ABInputField
                value={sent}
                onChange={handleInputChange(setSent)}
                placeholder="Sent"
            />
            <FormSubHeading>Delivered:</FormSubHeading>
            <ABInputField
                value={delivered}
                onChange={handleInputChange(setDelivered)}
                placeholder="Delivered"
            />
            <FormSubHeading>Open Rate:</FormSubHeading>
            <ABInputField
                value={openRate}
                onChange={handleInputChange(setOpenRate)}
                placeholder="Open Rate"
            />
            <FormSubHeading>Opens:</FormSubHeading>
            <ABInputField
                value={opens}
                onChange={handleInputChange(setOpens)}
                placeholder="Opens"
            />
            <FormSubHeading>Clicks:</FormSubHeading>
            <ABInputField
                value={clicks}
                onChange={handleInputChange(setClicks)}
                placeholder="Clicks"
            />
            <FormSubHeading>CTR:</FormSubHeading>
            <ABInputField
                value={ctr}
                onChange={handleInputChange(setCtr)}
                placeholder="CTR"
            />
            <FormSubHeading>CTOR:</FormSubHeading>
            <ABInputField
                value={ctor}
                onChange={handleInputChange(setCtor)}
                placeholder="CTOR"
            />
            <FormSubHeading>Bounce Rate:</FormSubHeading>
            <ABInputField
                value={bounceRate}
                onChange={handleInputChange(setBounceRate)}
                placeholder="Bounce Rate"
            />
            {/* <FormSubHeading>Delivery Rate:</FormSubHeading>
            <ABInputField
                value={deliveryRate}
                onChange={handleInputChange(setDeliveryRate)}
                placeholder="Delivery Rate"
            />
            <FormSubHeading>Unsubscribe Rate:</FormSubHeading>
            <ABInputField
                value={unsubscribeRate}
                onChange={handleInputChange(setUnsubscribeRate)}
                placeholder="Unsubscribe Rate"
            />
            <FormSubHeading>Spam Rate:</FormSubHeading>
            <ABInputField
                value={spamRate}
                onChange={handleInputChange(setSpamRate)}
                placeholder="Spam Rate"
            />
            <FormSubHeading>Growth Rate:</FormSubHeading>
            <ABInputField
                value={growthRate}
                onChange={handleInputChange(setGrowthRate)}
                placeholder="Growth Rate"
            /> */}

            <ActionButtons>
                <FooterButtons>
                    <ABButton title="Create Stat" onClick={onCreate} />
                    <TextButton title="cancel" onClick={onCancel}>Cancel</TextButton>
                </FooterButtons>
            </ActionButtons>
            <ToastContainer />
        </PageWrapper>
    );
};

const mapStateToProps = (state: any) => {
    return {
        error: state.statsEmail.error,
        success: state.statsEmail.success,
        alerts: state.alertItems.data
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    createStatsEmail: (queryParams?: object) => dispatch(createStatsEmail(queryParams)),
    getAlerts: (queryParams?: object) => dispatch(getAlertItems(queryParams))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailStatsForm);