const userEditingDefaultState = {
    data: {},
    loading: false,
    error: false,
    success: false,
    updateSuccess: false,
    successMessage: '',
    errorMessage: null
};

const userEditingReducer = (state = userEditingDefaultState, action) => {
    switch (action.type) {
        case 'START_GET_USER_EDITING':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'GET_USER_EDITING_SUCCESS':
            return {
                ...state,
                data: action.user,
                loading: false,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'START_USER_EDITING_UPDATE':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'UPDATE_USER_EDITING_SUCCESS':
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.user
                },
                loading: false,
                updateSuccess: true,
                error: false,
                errorMessage: null,
                successMessage: 'User updated successfully.',
            };
        case 'UPDATE_USER_EDITING_CLEAR_SUCCESS':
            return {
                ...state,
                updateSuccess: false,
                successMessage: '',
            };
        case 'SHOW_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
                successMessage: '',
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};

export default userEditingReducer;
