const usersDefaultState = {
  data: [],
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null
};

const usersReducer = (state = usersDefaultState, action) => {
  switch (action.type) {
    case 'START_GET_USERS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_USERS_SUCCESS':
      return {
        ...state,
        data: action.users,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default usersReducer;
