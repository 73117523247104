import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import colors from '../styles/colors';

interface FileSelectorProps {
    onFileSelect: (file: File | null) => void;
}

const DragArea = styled.p`
  background-color: ${colors.lightestGray};
  padding: 20px 10px;
  border-radius: 10px;
`

const FileSelector: React.FC<FileSelectorProps> = ({ onFileSelect }) => {
    const onDrop = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0] || null;
        onFileSelect(file);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.csv', // Customize file types if needed
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        onFileSelect(file);
    };

    return (
        <div className="file-selector">
            <div {...getRootProps()} className="dropzone">
                <input
                    {...getInputProps()}
                    type="file"
                    onChange={handleFileChange}
                    accept="csv/*" // Customize file types if needed
                />
                <DragArea>Drag & drop a CSV file here, or click to select one</DragArea>
            </div>
        </div>
    );
};

export default FileSelector;
