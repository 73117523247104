import axiosInstance from '../utility/axios';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetUsers = () => ({
  type: 'START_GET_USERS',
});

export const getUsersSuccess = (users) => ({
  type: 'GET_USERS_SUCCESS',
  users
});

export const getUsers = () => {
  return (dispatch) => {
    dispatch(startGetUsers());
    axiosInstance.get('users')
      .then((response) => {
        console.log('response: ', response);
        dispatch(getUsersSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          dispatch(showError(error.response.data.message));
        } else {
          dispatch(showError('Network Error'));
        }
        console.log(error);
      });
  }
};

export const userCreated = (userInfo) => ({
  type: 'USER_CREATED',
  userInfo
});

export const startCreateUser = (userInfo) => {
  return (dispatch) => {
    axiosInstance.post('user', userInfo)
      .then((response) => {
        dispatch(userCreated(userInfo));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(showError(error.response.data.message));
        } else {
          dispatch(showError('Network Error'));
        }
        console.log(error);
      });
  }
};

export const startUserUpdate = () => ({
  type: 'START_USER_UPDATE',
});

export const updateUserSuccess = (userDetails) => ({
  type: 'UPDATE_USER_SUCCESS',
  userDetails
});

export const updateUser = (userDetails, userId) => {
  console.log(userId);
  console.log(userDetails);
  return (dispatch) => {
    dispatch(startUserUpdate());
    console.log(userId);
    console.log(userDetails);

    axiosInstance.put(`user-admin/${userId}`, userDetails)
      .then((response) => {
        console.log('user updated');
        dispatch(updateUserSuccess(userDetails));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

