import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import { getClients } from "../../../actions/clients";
import { Client } from "../../../types/types";
import colors from '../../../styles/colors';
import ABButton from "../../../components/ABButton";
import { ToastContainer, toast } from "react-toastify";
import { getUserEditing, updateUserEditing } from "../../../actions/user-editing";

interface RouteParams {
  id: string;
}

interface UserDetails {
  email?: string;
  clientId?: string;
  role?: string;
}

const PageContainer = styled.div`
  padding: 20px 40px;
`;

const MainContentContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`

`;

const StyledHeading = styled.div`
  padding: 10px 0;
  font-size: 40px;
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledTitle = styled.div`
  padding: 20px 0 10px 0;
`;

const FormSection = styled.div`
  margin-bottom: 20px;
`;

const FormSubHeading = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 9px 10px 10px 10px;
  width: 450px;
  border: 1px solid ${colors.lightGray};
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

interface EditUserFormProps {
  // createEmailContentStat: (emailContentStat: EmailContentStat) => void;
  success?: string | null;
  updateSuccess?: string | null;
  error?: string | null;
  getClients: () => void;
  clients: Client[];
  udpateUser: () => void;
  user: UserDetails;
  getUserEditing: () => void;
  updateUserEditing: (userDetails: UserDetails, userId: string) => void;
}

const TextButton = styled.div`
    cursor: pointer;
    margin-right: 5px;
    color: ${colors.blue};
`;

const EditUser: React.FC<EditUserFormProps> = ({
  // createEmailContentStat,
  success,
  updateSuccess,
  error,
  getClients,
  clients,
  udpateUser,
  user,
  getUserEditing,
  updateUserEditing
}) => {

  const [clientId, setClientId] = useState('');

  const { id } = useParams<RouteParams>();

  useEffect(() => {
    getClients();
    getUserEditing();
  }, []);

  useEffect(() => {
    if (user.clientId) {
      setClientId(user.clientId);
    }
  }, [user]);

  useEffect(() => {
    if (updateSuccess) {
      toast.success("User Updated Successfully");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (error) {
      toast.error("Error Updating User");
    }
  }, [error]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setClientId(e.target.value);
  }

  const handleSubmit = () => {
    console.log('submitting?');
    console.log('clientId: ', clientId);

    if (!clientId) {
      toast.error("Please fill out all fields.");
      return;
    }

    const userDetails = {
      clientId: clientId
    }
    updateUserEditing(userDetails, id);
  };

  return (
    <PageContainer>
      <HeadingContainer>
        <StyledHeading>Edit User ID: {id}</StyledHeading>
      </HeadingContainer>
      <MainContentContainer>
        <MainContent>
          <StyledTitle>Select a client that the user can access stat info.</StyledTitle>
          <FormSubHeading>Client ID:</FormSubHeading>
          {
            (clients && clients.length > 0) &&
            <StyledSelect
              name="clientId"
              value={clientId}
              onChange={handleSelectChange}
              placeholder="Enter Client Id"
            >
              <option value="">Select</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.company} - {client.id}
                </option>
              ))}
            </StyledSelect>
          }
        </MainContent>
      </MainContentContainer>
      <ActionButtons>
        <FooterButtons>
          <ABButton title="Allow Access" onClick={handleSubmit} />
        </FooterButtons>
      </ActionButtons>

      <ToastContainer />
    </PageContainer >
  );
}

const mapStateToProps = (state: any) => {
  return {
    error: state.userEditing.error,
    success: state.userEditing.success,
    updateSuccess: state.userEditing.updateSuccess,
    clients: state.clients.data,
    user: state.userEditing.data
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getClients: () => dispatch(getClients()),
  getUserEditing: () => dispatch(getUserEditing()),
  updateUserEditing: (userDetails: UserDetails, userId: string) => dispatch(updateUserEditing(userDetails, userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);